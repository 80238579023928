import { graphql } from 'gatsby'
import React from 'react'
import ContentPage from '~/components/Content/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const IndexPage = ({ location: { pathname }, data }) => {
  return (
    <Layout showBreadcrumbs={false}>
      <Metadata
        title={data.home.metaTitle || data.home.title}
        description={
          data.home.metaDescription?.metaDescription ||
          `Super73's ${data.home.title} page default description`
        }
      />
      <ContentPage page={data.home} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      ...ContentPageFragment
    }
  }
`
